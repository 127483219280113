import { takeLatest, put, select, call } from 'redux-saga/effects';
import { setLoadPractices, setPractices} from './practicesSlice';
import { authSliceSelector, setAuthError} from '../auth/authSlice';
import { fetchPracticesListCall, getFreeAppointmentsPracticeCall } from '../../utils/apiCalls';
import { fetchPractices, getFreeAppointments, setfreeAppointments } from './practicesSlice';
import _ from 'lodash';
export interface ResponseData{
  data: Array<any> | null,
  status: number,
  statusText: string,
}

function* fetchPracticesSaga() {
  try {
    yield put(setLoadPractices(true));
    const { token } = yield select(authSliceSelector);
    const res: ResponseData = yield call(fetchPracticesListCall, token);
    if (res.data) {
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        const onlyActivePractices = res.data.filter(p => p.active);
        yield put(setPractices(onlyActivePractices));
        yield put(setLoadPractices(false));
        yield put(setAuthError(null));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

function* getFreeAppointmentsSaga(action: any) {
  try {
    const { token } = yield select(authSliceSelector);
    yield put(setLoadPractices(true));
    const res: ResponseData = yield call(getFreeAppointmentsPracticeCall, token, action.payload);
    if (res.data) {
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        const sortedSlots = _.sortBy(res.data, (entry) => {return entry.start;});
        yield put(setfreeAppointments(sortedSlots));
        yield put(setAuthError(null));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

export function* watchPracticesSaga() {
  yield takeLatest(fetchPractices.type, fetchPracticesSaga);
  yield takeLatest(getFreeAppointments.type, getFreeAppointmentsSaga);
}
